import React from "react"
import { Link, graphql } from "gatsby"

import {
  MdArrowBack,
} from 'react-icons/md';


import SEO from "../components/seo"
import Layout from "../components/layout"

const ProviderWisp = ({data: {image1,wisp }}) => {


    let imgProd = wisp.childImageSharp.fluid.src;

    return (
        <Layout changeHeader={2}>
            <SEO title={`Proveedor WISP`} />

            <section className="hero-wrap hero-wrap-2 mb-2" style={{backgroundImage: `url(${image1.childImageSharp.fluid.src})`}} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                    <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                        <Link to ="/#services-section" className="icon ml-5 btn btn-primary" title="Regresar">
                            <MdArrowBack
                                color="#fff"
                                size="2.3em"
                            /> 
                        </Link>
                        
                    </div>
                    <div className="col-md-11 text-center">
                        <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease">Proveedor WISP </h1>
                        <p className="breadcrumbs"><span className="mr-2">
                            
                                <Link  className="" to="/#services-section">Servicios</Link> > </span> <span>Proveedor WISP</span>
                            </p>
                    </div>
                    </div>
                </div>
            </section>
            <section className="justify-content-center text-justify">
            
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7 col-12">
                            <div className="sidebar-box" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                <h3 className="text-center">Proveedor WISP (Proveedor de internet inálambrico)</h3>

                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Un proveedor de servicios de Internet inalámbrico (en inglés, wireless Internet service provider, abreviado WISP) es un proveedor de servicios de Internet con una red basada en conexiones inalámbricas.</p>

                                <p>&nbsp;&nbsp;&nbsp;&nbsp;La solución permite crear una elevada densidad de cobertura sin necesidad de emplear técnicas complementarias, algo parecido a instalar 200 ADSL en una ciudad pero con sólo un router wifi para dar acceso a los usuarios de forma inalámbrica.
                                La solución es muy potente ya que permite alta densidad de cobertura con muy baja potencia de antenas, de hecho femtocelda es una de las opciones futuras para las redes de los operadores móviles.</p>
                            </div>
                        </div>

                        <div className="col-md-5 col-12 p-md-7 mt-5 " data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >
                            <img src={imgProd} alt="img project" className="img-fluid rounded mb-1 " />
                        </div>

                        <div className="col-md-12 col-12 p-md-4 mt-3" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >

                            


                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ProviderWisp

export const pageQuery = graphql`
  query FacByWisp {

    image1: file(relativePath: { eq: "wisp_banner.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    } 
   
    wisp: file(relativePath: { eq: "wisp.jpg" }) {
      childImageSharp {
          fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
          }
      }
    }

  }
`